import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-posts-yet',
  templateUrl: './no-posts-yet.component.html',
  styleUrls: ['./no-posts-yet.component.scss'],
})
export class NoPostsYetComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
