import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    status = new BehaviorSubject<boolean>(false);
    status$: Observable<boolean> = this.status.asObservable();

    private localStorage: Storage | null = null;

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        const storage = await this.storage.create();
        this.localStorage = storage;
        this.status.next(true);
    }

    public set(key: string, value: any) {
        this.localStorage.set(key, value);
    }

    public async get(key: string) {
        return this.localStorage?.get(key);
    }
}
