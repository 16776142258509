/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { User } from '../models/user';
import { environment } from 'src/environments/environment';
const { cloudFunctionsUri } = environment;

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    user: any;
    userSubject = new BehaviorSubject<User>({
        uid: null,
        email: null,
        tag: null,
        displayName: null,
        description: null,
        category: null,
        photoURL: null,
        emailVerified: null,
        role: null,
        gender: null,
        lastUpdateGroups: null,
        followers: 0,
        followings: 0,
        subscribers: 0,
        interests: null,
        notInterests: null,
    });

    user$: Observable<User> = this.userSubject.asObservable();
    userId: string;

    private userRole = 'user';
    private usersColecction = this.firestore.collection('users');

    constructor(
        public firestore: AngularFirestore,
        public afAuth: AngularFireAuth
    ) {
        this.afAuth.user.subscribe((user) => {
            if (user) {
                this.userId = user.uid;

                this.getUserData().then((response) => {
                    const data = response.data();
                    this.user = data;

                    data.lastUpdateGroups = new Date(
                        data.lastUpdateGroups.seconds * 1000
                    );
                    this.userSubject.next(data);
                }).finally(() => {
                    localStorage.setItem('user', JSON.stringify(this.user));
                    JSON.parse(localStorage.getItem('user'));
                });
            } else {
                this.user = null;
                this.userId = '';
                this.userSubject.next(null);
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            }
        });
    }
    // Returns true when user's email is verified
    get isEmailVerified(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.emailVerified !== false ? true : false;
    }

    // SignIn in with email/password
    signInWithEmail(email, password) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth
                .signInWithEmailAndPassword(email, password)
                .then((resp) => {
                    resp.user
                        .getIdTokenResult()
                        .then((data: any) => {
                            if (data.claims) {
                                this.userId = data.claims.user_id;
                                resolve(data);
                            } else {
                                this.afAuth.signOut();
                                reject({ code: 'auth/unauthorized' });
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    signInWithGoogle() {
        return this.afAuth.signInWithPopup(
            new firebase.auth.GoogleAuthProvider()
        );
    }

    // SignUp user with email/password
    signUpUser(email, password) {
        return new Promise((resolve, reject) => {
            this.afAuth
                .createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    userCredential.user
                        .sendEmailVerification()
                        .then(() => {
                            const user: User = {
                                uid: userCredential.user.uid,
                                email: userCredential.user.email,
                                tag: '',
                                displayName: '',
                                description: '',
                                category: 'General',
                                photoURL: userCredential.user.photoURL,
                                emailVerified: userCredential.user.emailVerified,
                                role: 'user',
                                gender: '',
                                lastUpdateGroups: new Date(0),
                                followers: 0,
                                followings: 0,
                                subscribers: 0,
                                interests: [],
                                notInterests: []
                            };

                            this.usersColecction
                                .doc(`${user.uid}`)
                                .set(user)
                                .then(() => {
                                    resolve(true);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
        });
    }

    // Recover password
    passwordRecover(email: string) {
        return this.afAuth.sendPasswordResetEmail(email);
    }

    async updateUser(user: User) {
        this.userSubject.next(user);

        const userRef = this.usersColecction.doc(user.uid);
        return await userRef.update(user);
    }

    initialiceUserData(userData) {
        const user: User = {
            uid: userData.uid,
            email: userData.email,
            tag: userData.tag,
            displayName: userData.displayName,
            description: userData.description,
            category: userData.category,
            photoURL: userData.photoURL,
            emailVerified: userData.emailVerified,
            role: 'user',
            gender: '',
            lastUpdateGroups: new Date(0),
            followers: 0,
            followings: 0,
            subscribers: 0,
            interests: [],
            notInterests: []
        };

        const userRef: AngularFirestoreDocument<any> = this.usersColecction.doc(
            `${user.uid}`
        );
        return userRef.set(user);
    }

    // Sign-out
    signOut() {
        return this.afAuth.signOut();
    }

    getUserData(userId?: string): Promise<any> {
        const userRef: AngularFirestoreDocument<any> = this.usersColecction.doc(
            `${userId || this.userId}`
        );
        return userRef.get().toPromise();
    }

    verifyUserTag(userTag: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.firestore
                .collection('users', (ref) => ref.where('tag', '==', userTag))
                .get()
                .toPromise()
                .then((response) => {
                    if (response.empty) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async forgotPassword(email: string) {
        const body = { email };
        const fetchOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };

        const response = await fetch(`${cloudFunctionsUri}/forgotPassword`, fetchOptions);
        return await response.json();
    }

    async resetPassword(email: string, code: string, password: string) {
        const body = { email, code, password };
        const fetchOptions = {
            method: 'POST',
            headers: {
                //eslint-disable-next-line
                Accept: 'application/json',
                //eslint-disable-next-line
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };


        const response = await fetch(`${cloudFunctionsUri}/resetPassword`, fetchOptions);
        return await response.json();

    }

    async verifyCode(email: string, code: number): Promise<boolean> {
        const userQuery = await this.firestore.collection('users', (ref) => ref.where('email', '==', email)).get().toPromise();
        const userDoc = userQuery.docs[0];
        const user = userDoc.data() as User;

        if (user.resetDate > Date.now() - 3600000) {
            if (code === user.resetCode) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
