// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    cloudFunctionsUri: 'https://us-central1-wocoon-llc.cloudfunctions.net',
    // cloudFunctionsUri: 'http://localhost:5001/wocoon-llc/us-central1',
    firebaseConfig: {
        apiKey: 'AIzaSyCW30oHHT42NyYWY54XTHJoJ2MMUl5NFBU',
        authDomain: 'wocoon-llc.firebaseapp.com',
        projectId: 'wocoon-llc',
        storageBucket: 'wocoon-llc.appspot.com',
        messagingSenderId: '299200228058',
        appId: '1:299200228058:web:dae71f3a81980c78f713e3',
    },
    stripeConfig: {
        stripeKey:
            'pk_test_51IkaXzIeOBJ7pVgCUerxaSh17EOlihOqKef0lezZs5UiZaq0rE9TXlv63aNPjGmw89PPEtKfxfulushbnptz72y700ajcviWj8',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
