import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterFeedComponent } from './components/footer-feed/footer-feed.component';
import { NoPostsYetComponent } from './components/no-posts-yet/no-posts-yet.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FilterPipe } from './pipes/filter.pipe';
import { ProfileOptionsComponent } from './components/profile-options/profile-options.component';
import { SwiperModule } from 'swiper/angular';
import { CommentComponent } from '../feed/pages/post/components/comments/comment.component';
import { SingleCommentComponent } from '../feed/pages/post/components/single-comment/single-comment.component';

const components = [
    FooterFeedComponent,
    NoPostsYetComponent,
    ProfileOptionsComponent,
    FilterPipe,
    CommentComponent,
    SingleCommentComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        SwiperModule,
    ],
    exports: [
        ...components,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        SwiperModule,
    ],
})
export class SharedModule {}
