/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    Injector,
    NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActionSheetController, IonicModule } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { ConfigService } from './shared/services/config.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './shared/services/storage.service';

import { Camera } from '@ionic-native/camera/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { SwiperModule } from 'swiper/angular';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';

import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideFirestore(() => getFirestore()),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        SharedModule,
        MaterialModule,
        HttpClientModule,
        AuthGuardModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        IonicStorageModule.forRoot(),
        SwiperModule,
    ],
    providers: [
        ConfigService,
        StorageService,
        Camera,
        ActionSheetController,
        SocialSharing,
        MediaCapture,
        {
            provide: APP_INITIALIZER,
            useFactory: (cs: ConfigService) => () => cs.load(),
            deps: [ConfigService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    static injector: Injector;

    constructor(private injector: Injector) {
        AppModule.injector = injector;
    }
}
