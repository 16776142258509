import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    QuerySnapshot,
} from '@angular/fire/compat/firestore';

import { Comment } from '../models/comment';
import { UserRef } from '../models/user';
import { LikeService } from './like.service';
interface CommentParams {
    text: string;
    user: UserRef;
    commentID?: string;
}
interface LikeParams {
    commentID: string;
    postID: string;
}
@Injectable({
    providedIn: 'root',
})
export class CommentService {
    public collectionName = 'posts';
    public maxComments = 3;
    public initialize = true;
    // public lastComment = null;
    // private collection: AngularFirestoreCollection<Comment>;

    constructor(
        private firestore: AngularFirestore,
        private likeService: LikeService
    ) {}

    async commentCount(postId: string) {
        if (!this.initialize) {
            return;
        }
        const comments = [];
        const commentsRefs = await this.firestore
            .collection(`${this.collectionName}/${postId}/comments`, (ref) =>
                ref.where('isReply', '==', false)
            )
            .get()
            .toPromise();
        commentsRefs.forEach((comment) => {
            comments.push(comment);
        });
        return comments.length;
    }

    async repliesCount(postId: string, commentID: string) {
        if (!this.initialize) {
            return;
        }
        const comments = [];
        const commentsRefs = await this.firestore
            .collection(`${this.collectionName}/${postId}/comments`, (ref) =>
                ref
                    .where('commentID', '==', commentID)
                    .where('isReply', '==', true)
            )
            .get()
            .toPromise();

        commentsRefs.forEach((comment) => {
            comments.push(comment);
        });
        return comments.length;
    }

    async getComments(start: number, postId: string) {
        if (!this.initialize) {
            return;
        }
        const comments: Comment[] = [];
        let commentsRefs: QuerySnapshot<unknown>;

        if (start) {
            commentsRefs = await this.firestore
                .collection(
                    `${this.collectionName}/${postId}/comments`,
                    (ref) =>
                        ref
                            .where('isReply', '==', false)
                            .orderBy('date', 'desc')
                            .startAfter(start)
                            .limit(this.maxComments)
                )
                .get()
                .toPromise();
        } else {
            commentsRefs = await this.firestore
                .collection(
                    `${this.collectionName}/${postId}/comments`,
                    (ref) =>
                        ref
                            .where('isReply', '==', false)
                            .orderBy('date', 'desc')
                            .limit(this.maxComments)
                )
                .get()
                .toPromise();
        }

        if (commentsRefs?.empty) {
            return { comments: [], commentsRefs: [] };
        }

        commentsRefs.forEach((ref: any) => {
            comments.push(ref.data());
        });

        return { comments, commentsRefs: commentsRefs.docs };
    }

    async getReplyComments(start: number, postId: string, commentID: string) {
        if (!this.initialize) {
            return;
        }
        const comments: Comment[] = [];
        let commentsRefs: QuerySnapshot<unknown>;

        if (start) {
            commentsRefs = await this.firestore
                .collection(
                    `${this.collectionName}/${postId}/comments`,
                    (ref) =>
                        ref
                            .where('commentID', '==', commentID)
                            .orderBy('date', 'desc')
                            .startAfter(start)
                            .limit(this.maxComments)
                )
                .get()
                .toPromise();
        } else {
            commentsRefs = await this.firestore
                .collection(
                    `${this.collectionName}/${postId}/comments`,
                    (ref) =>
                        ref
                            .where('commentID', '==', commentID)
                            .orderBy('date', 'desc')
                            .limit(this.maxComments)
                )
                .get()
                .toPromise();
        }
        if (commentsRefs?.empty) {
            return { comments: [], commentsRefs: [] };
        }
        commentsRefs.forEach((ref: any) => {
            comments.push(ref.data());
        });
        return { comments, commentsRefs: commentsRefs.docs };
    }
    async createComments(comment: CommentParams, postID: string) {
        if (!this.initialize) {
            return;
        }
        if (!comment.text || !comment.user.uid) {
            return;
        }
        const newComment: Comment = {
            uid: '',
            date: new Date(Date.now()),
            isReply: comment.commentID ? true : false,
            ...comment,
        };
        const collection: AngularFirestoreCollection<Comment> =
            this.firestore.collection(
                `${this.collectionName}/${postID}/comments`
            );
        const commentRef = await collection.add(newComment);
        await collection.doc(commentRef.id).update({ uid: commentRef.id });

        return (await collection.doc(commentRef.id).get().toPromise()).data();
    }
    async toggleLikeComment({ commentID, postID }: LikeParams) {
        await this.likeService.toggleLike(
            `${this.collectionName}/${postID}/comments/${commentID}`
        );
    }

    async likeCount({ commentID, postID }: LikeParams) {
        return await this.likeService.likeCount(
            `${this.collectionName}/${postID}/comments/${commentID}`
        );
    }
    async isLiked(commentID: string, postID: string) {
        return await this.likeService.isLiked(
            `${this.collectionName}/${postID}/comments/${commentID}`
        );
    }
}
