import { Component, Input, OnInit } from '@angular/core';
import { CommentService } from 'src/app/shared/services/comments.service';
import { Comment } from 'src/app/shared/models/comment';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { LikeService } from 'src/app/shared/services/like.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
    @Input() private comment: Comment;
    @Input() private isReply: boolean;
    @Input() private postID: string;
    public repliesComments: Comment[] = [];
    public addComment = false;
    public commentText = '';
    public repliesCount = 0;
    public liked = false;
    public likesCount = 0;
    public showMoreComments = false;

    public lastReply = null;

    public commentOffset = 0;
    constructor(
        private commentService: CommentService,
        private auth: AuthenticationService,
        private likeService: LikeService,
        private router: Router
    ) {}

    ngOnInit() {
        (async () => {
            await this.getMoreComments();

            await this.setRepliesCount();
            this.liked = await this.commentService.isLiked(
                this.comment.uid,
                this.postID
            );
            if (this.repliesCount > this.repliesComments.length) {
                this.showMoreComments = true;
            }
            await this.likeCount();
        })();
    }

    toggleReplyInput() {
        this.addComment = !this.addComment;
    }
    async getMoreComments() {
        const count = await this.commentService.repliesCount(
            this.postID,
            this.comment.uid
        );
        if (count > this.repliesComments.length) {
            this.commentOffset++;

            const { comments: newComments, commentsRefs } =
                await this.commentService.getReplyComments(
                    this.lastReply,
                    this.postID,
                    this.comment.uid
                );
            this.lastReply =
                commentsRefs[commentsRefs.length - 1] || this.lastReply;

            this.repliesComments = [...this.repliesComments, ...newComments];
        } else if (count <= this.repliesComments.length) {
            this.showMoreComments = false;
        }
    }
    async setRepliesCount() {
        this.repliesCount = await this.commentService.repliesCount(
            this.postID,
            this.comment.uid
        );
    }
    getTimeAgo(date: string) {
        return moment(date).fromNow();
    }
    async createComment() {
        if (!this.commentText) {
            return;
        }

        const userData = await (await this.auth.getUserData()).data();

        const user = {
            uid: userData.uid,
            photoURL: userData.photoURL || '',
            tag: userData.tag,
        };

        const newComment = {
            postID: this.postID,
            commentID: this.comment.uid,
            text: this.commentText,
            user,
        };

        const createdComment = await this.commentService.createComments(
            newComment,
            this.postID
        );

        this.repliesComments.push(createdComment);
        this.commentText = '';
        await this.setRepliesCount();
    }
    async likeCount() {
        this.likesCount = await this.commentService.likeCount({
            postID: this.postID,
            commentID: this.comment.uid,
        });
    }

    async like() {
        this.liked = !this.liked;
        await this.commentService.toggleLikeComment({
            commentID: this.comment.uid,
            postID: this.postID,
        });
        await this.likeCount();
        this.liked = await this.commentService.isLiked(
            this.comment.uid,
            this.postID
        );
    }

    goTo(url: string) {
        this.router.navigate([url]);
    }
}
