import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
    selector: 'app-footer-feed',
    templateUrl: './footer-feed.component.html',
    styleUrls: ['./footer-feed.component.scss'],
})
export class FooterFeedComponent implements OnInit {
    @Input() url;
    public user: User;

    constructor(
        private router: Router,
        private auth: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.auth.getUserData().then((response) => {
            this.user = response.data();
        });
    }

    goTo(url: string) {
        this.router.navigate([url]);
    }
}
