import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-profile-options',
    templateUrl: './profile-options.component.html',
    styleUrls: ['./profile-options.component.scss'],
})
export class ProfileOptionsComponent implements OnInit {
    @Input() userObserved: any;

    constructor(
        private auth: AuthenticationService,
        private router: Router
    ) { }

    ngOnInit() {}

    goTo(url: string) {
        this.router.navigate([url]);
    }
}
