import { Component,Input, OnInit } from '@angular/core';
import { Comment } from 'src/app/shared/models/comment';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-single-comment',
    templateUrl: './single-comment.component.html',
    styleUrls: ['./single-comment.component.scss'],
})
export class SingleCommentComponent implements OnInit {
    @Input() private comment: Comment;
    @Input() private postID: string;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {}

    getTimeAgo(date: string) {
        return moment(date).fromNow();
    }

    goTo(url: string) {
        this.router.navigate([url]);
    }
}
