import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserRef } from '../models/user';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class LikeService {
    constructor(
        private firebase: AngularFirestore,
        private auth: AuthenticationService
    ) {}

    async likeCount(collectionName: string) {
        const collection = await this.firebase
            .collection(`${collectionName}/likes`)
            .get()
            .toPromise();

        return collection.size;
    }
    async like(collectionName: string, like: { user: UserRef }) {
        const collection = this.firebase.collection(`${collectionName}/likes`);
        const likeRef = await collection.add(like);
        await collection.doc(likeRef.id).update({ uid: likeRef.id });
        return (await collection.doc(likeRef.id).get().toPromise()).data();
    }

    async unlike(collectionName: string, likeId: string) {
        await this.firebase
            .collection(`${collectionName}/likes`)
            .doc(likeId)
            .delete();

        return true;
    }
    async isLiked(collectionName: string) {
        const user = (await this.auth.getUserData()).data();
        const collection = this.firebase.collection(
            `${collectionName}/likes`,
            (ref) => ref.where('user.uid', '==', user.uid)
        );
        const isLiked = await collection.get().toPromise();

        return !isLiked.empty;
    }

    async toggleLike(collectionName: string) {
        const user = (await this.auth.getUserData()).data();
        const userRef: UserRef = {
            uid: user.uid,
            photoURL: user.photoURL,
            tag: user.tag,
        };

        const isLiked = await this.firebase
            .collection(`${collectionName}/likes`, (ref) =>
                ref.where('user.uid', '==', user.uid)
            )
            .get()
            .toPromise();
        if (isLiked.empty) {
            await this.like(collectionName, { user: userRef });
        } else {
            const likes = [];
            isLiked.forEach((like) => {
                likes.push(like.data());
            });
            const promises = likes.map((like) =>
                this.unlike(collectionName, like.uid)
            );
            await Promise.all(promises);
        }
    }
}
