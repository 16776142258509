import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private genderList = [];
    private categoriesList = [];

    private configCollection: AngularFirestoreCollection<any>;
    private listDocument: AngularFirestoreDocument<any>;

    constructor(private firestore: AngularFirestore) {
        this.configCollection = this.firestore.collection('config');
        this.listDocument = this.configCollection.doc('lists');
    }

    async load(): Promise<any> {
        return this.listDocument
            .get()
            .toPromise()
            .then((doc) => {
                const data = doc.data();

                this.genderList = data.genders;
                this.categoriesList = data.categories;
            });
    }

    getLists() {
        return {
            genderList: this.genderList,
            categoriesList: this.categoriesList,
        };
    }
}
