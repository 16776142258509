import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectiveLoadingStrategy } from './shared/utils/selective-loading-strategy.service';
import {
    canActivate,
    redirectUnauthorizedTo,
    redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['feed/home']);

const routes: Routes = [
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    {
        path: 'feed',
        ...canActivate(redirectUnauthorizedToLogin),
        loadChildren: () => import('./feed/feed.module').then((m) => m.FeedModule),
    },
    {
        path: 'profile',
        ...canActivate(redirectUnauthorizedToLogin),
        loadChildren: () =>
            import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'chat',
        ...canActivate(redirectUnauthorizedToLogin),
        loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
    },
    {
        path: 'config',
        ...canActivate(redirectUnauthorizedToLogin),
        loadChildren: () =>
            import('./config/config.module').then((m) => m.ConfigModule),
    },
    {
        path: 'auth',
        ...canActivate(redirectLoggedInToHome),
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '**',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: SelectiveLoadingStrategy,
        }),
    ],
    exports: [RouterModule],
    providers: [SelectiveLoadingStrategy],
})
export class AppRoutingModule {}
