/* eslint-disable max-len */
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { GetResult, SetOptions, TextZoom } from '@capacitor/text-zoom';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    public url: string;
    public seeNavBar = false;

    constructor(
        private platform: Platform,
        private translate: TranslateService,
        private router: Router
    ) {
        this.initializeApp();

        this.router.events.subscribe((response) => {
            if (response instanceof NavigationEnd) {
                const navigation = response as NavigationEnd;
                this.url = navigation.url;
                if (
                    this.url.includes('/feed/home') ||
          this.url.includes('/feed/search') ||
          this.url.includes('/feed/notifications')
                ) {
                    this.seeNavBar = true;
                } else {
                    this.seeNavBar = false;
                }
            }
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.translate.setDefaultLang('es');
            this.translate.use('es');

            if (!this.platform.is('mobileweb')) {
                TextZoom.set({
                    value: 1
                });
            }
        });
    }
}
